import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Decorating a client";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "decorating-a-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#decorating-a-client",
        "aria-label": "decorating a client permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating a client`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#implementing-decoratinghttpclientfunction-and-decoratingrpcclientfunction"
          }}>{`Implementing DecoratingHttpClientFunction and DecoratingRpcClientFunction`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#extending-simpledecoratinghttpclient-and-simpledecoratingrpcclient"
          }}>{`Extending SimpleDecoratingHttpClient and SimpleDecoratingRpcClient`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#the-order-of-decoration"
          }}>{`The order of decoration`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#modifying-responses"
          }}>{`Modifying responses`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-transforming-methods"
            }}>{`Using transforming methods`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-typefilteredhttpresponse"
            }}>{`Using type://FilteredHttpResponse:`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#reacting-to-or-logging-responses"
          }}>{`Reacting to or logging responses`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#see-also"
          }}>{`See also`}</a></p>
      </li>
    </ul>
    <p>{`A 'decorating client' (or a 'decorator') is a client that wraps another client to intercept an outgoing
request or an incoming response. As its name says, it is an implementation of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Decorator_pattern"
      }}>{`the decorator pattern`}</a>{`.
Client decoration takes a crucial role in Armeria. A lot of core features such as logging, metrics and
distributed tracing are implemented as decorators and you will also find it useful when `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Separation_of_concerns"
      }}>{`separating concerns`}</a>{`.`}</p>
    <p>{`There are basically two ways to write a decorating client:`}</p>
    <ul>
      <li parentName="ul">{`Implementing `}<a parentName="li" {...{
          "href": "type://DecoratingHttpClientFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DecoratingHttpClientFunction.html"
        }}>{`type://DecoratingHttpClientFunction`}</a>{` and `}<a parentName="li" {...{
          "href": "type://DecoratingRpcClientFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DecoratingRpcClientFunction.html"
        }}>{`type://DecoratingRpcClientFunction`}</a></li>
      <li parentName="ul">{`Extending `}<a parentName="li" {...{
          "href": "type://SimpleDecoratingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/SimpleDecoratingClient.html"
        }}>{`type://SimpleDecoratingClient`}</a></li>
    </ul>
    <h2 {...{
      "id": "implementing-decoratinghttpclientfunction-and-decoratingrpcclientfunction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#implementing-decoratinghttpclientfunction-and-decoratingrpcclientfunction",
        "aria-label": "implementing decoratinghttpclientfunction and decoratingrpcclientfunction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing `}<inlineCode parentName="h2">{`DecoratingHttpClientFunction`}</inlineCode>{` and `}<inlineCode parentName="h2">{`DecoratingRpcClientFunction`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://DecoratingHttpClientFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DecoratingHttpClientFunction.html"
      }}>{`type://DecoratingHttpClientFunction`}</a>{` and `}<a parentName="p" {...{
        "href": "type://DecoratingRpcClientFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DecoratingRpcClientFunction.html"
      }}>{`type://DecoratingRpcClientFunction`}</a>{` are functional interfaces that
greatly simplify the implementation of a decorating client.
They enable you to write a decorating client with a single lambda expression:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;

ClientBuilder cb = Clients.builder(...);
...
cb.decorator((delegate, ctx, req) -> {
    auditRequest(req);
    return delegate.execute(ctx, req);
});

MyService.Iface client = cb.build(MyService.Iface.class);
`}</code></pre>
    <h2 {...{
      "id": "extending-simpledecoratinghttpclient-and-simpledecoratingrpcclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#extending-simpledecoratinghttpclient-and-simpledecoratingrpcclient",
        "aria-label": "extending simpledecoratinghttpclient and simpledecoratingrpcclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extending `}<inlineCode parentName="h2">{`SimpleDecoratingHttpClient`}</inlineCode>{` and `}<inlineCode parentName="h2">{`SimpleDecoratingRpcClient`}</inlineCode></h2>
    <p>{`If your decorator is expected to be reusable, it is recommended to define a new top-level class that extends
`}<a parentName="p" {...{
        "href": "type://SimpleDecoratingHttpClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/SimpleDecoratingHttpClient.html"
      }}>{`type://SimpleDecoratingHttpClient`}</a>{` or `}<a parentName="p" {...{
        "href": "type://SimpleDecoratingRpcClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/SimpleDecoratingRpcClient.html"
      }}>{`type://SimpleDecoratingRpcClient`}</a>{` depending on whether
you are decorating an `}<a parentName="p" {...{
        "href": "type://HttpClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/HttpClient.html"
      }}>{`type://HttpClient`}</a>{` or an `}<a parentName="p" {...{
        "href": "type://RpcClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RpcClient.html"
      }}>{`type://RpcClient`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.HttpClient;
import com.linecorp.armeria.client.SimpleDecoratingHttpClient;

public class AuditClient extends SimpleDecoratingHttpClient {
    public AuditClient(HttpClient delegate) {
        super(delegate);
    }

    @Override
    public HttpResponse execute(ClientRequestContext ctx, HttpRequest req) throws Exception {
        auditRequest(req);
        return unwrap().execute(ctx, req);
    }
}

ClientBuilder cb = Clients.builder(...);
...
// Using a lambda expression:
cb.decorator(delegate -> new AuditClient(delegate));
`}</code></pre>
    <h2 {...{
      "id": "the-order-of-decoration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-order-of-decoration",
        "aria-label": "the order of decoration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The order of decoration`}</h2>
    <p>{`The decorators are executed in reverse order of the insertion. The following example shows which order
the decorators are executed by printing the messages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;

ClientBuilder cb = Clients.builder(...);

// #2 decorator
cb.decorator((delegate, ctx, req) -> {
    // This is called from #1 decorator.
    System.err.println("Secondly, executed.");
    return delegate.execute(ctx, req);
});

// #1 decorator.
// No matter decorator() or option() is used, decorators are executed in reverse order of the insertion.
cb.option(ClientOption.DECORATION, ClientDecoration.of((delegate, ctx, req) -> {
    System.err.println("Firstly, executed");
    return delegate.execute(ctx, req); // The delegate, here, is #2 decorator.
                                       // This will call execute(ctx, req) method on #2 decorator.
});

WebClient myClient = cb.build(WebClient.class);
`}</code></pre>
    <p>{`The following diagram describes how an HTTP request and HTTP response are gone through decorators:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "864",
        "height": "112",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "864",
          "height": "112"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "122",
          "y": "12"
        }}>{`req`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "12"
        }}>{`req`}</text>{`
  `}<text parentName="svg" {...{
          "x": "442",
          "y": "12"
        }}>{`req`}</text>{`
  `}<text parentName="svg" {...{
          "x": "674",
          "y": "12"
        }}>{`req`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "152,20 160,24 152,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "312,20 320,24 312,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "472,20 480,24 472,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "720,20 728,24 720,28",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "122",
          "y": "60"
        }}>{`res`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "60"
        }}>{`res`}</text>{`
  `}<text parentName="svg" {...{
          "x": "442",
          "y": "60"
        }}>{`res`}</text>{`
  `}<text parentName="svg" {...{
          "x": "674",
          "y": "60"
        }}>{`res`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "112,68 104,72 112,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "272,68 264,72 272,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "432,68 424,72 432,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "648,68 640,72 648,76",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "44"
        }}>{`WebClient`}</text>{`
  `}<text parentName="svg" {...{
          "x": "178",
          "y": "44"
        }}>{`#1`}</text>{`
  `}<text parentName="svg" {...{
          "x": "178",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "338",
          "y": "44"
        }}>{`#2`}</text>{`
  `}<text parentName="svg" {...{
          "x": "338",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "498",
          "y": "44"
        }}>{`Armeria`}</text>{`
  `}<text parentName="svg" {...{
          "x": "498",
          "y": "60"
        }}>{`Networking`}</text>{`
  `}<text parentName="svg" {...{
          "x": "770",
          "y": "44"
        }}>{`Server`}</text>{`
  `}<text parentName="svg" {...{
          "x": "586",
          "y": "60"
        }}>{`Layer`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "100",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "4",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "100",
            "y1": "8",
            "x2": "100",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "100",
            "y1": "24",
            "x2": "152",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "88",
            "x2": "100",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "164",
            "y1": "8",
            "x2": "260",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "164",
            "y1": "8",
            "x2": "164",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "260",
            "y1": "8",
            "x2": "260",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "260",
            "y1": "24",
            "x2": "312",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "112",
            "y1": "72",
            "x2": "164",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "164",
            "y1": "88",
            "x2": "260",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "324",
            "y1": "8",
            "x2": "420",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "324",
            "y1": "8",
            "x2": "324",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "420",
            "y1": "8",
            "x2": "420",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "420",
            "y1": "24",
            "x2": "472",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "272",
            "y1": "72",
            "x2": "324",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "324",
            "y1": "88",
            "x2": "420",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "484",
            "y1": "8",
            "x2": "636",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "484",
            "y1": "8",
            "x2": "484",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "636",
            "y1": "8",
            "x2": "636",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "636",
            "y1": "24",
            "x2": "720",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "432",
            "y1": "72",
            "x2": "484",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "484",
            "y1": "88",
            "x2": "636",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "732",
            "y1": "8",
            "x2": "852",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "732",
            "y1": "8",
            "x2": "732",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "852",
            "y1": "8",
            "x2": "852",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "648",
            "y1": "72",
            "x2": "732",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "732",
            "y1": "88",
            "x2": "852",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`If the client is a Thrift client and RPC decorators are used, HTTP decorators and RPC decorators are
separately grouped and executed in reverse order of the insertion:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientBuilder cb = Clients.builder(...);

// #2 HTTP decorator.
cb.decorator((delegate, ctx, httpReq) -> {
    System.err.println("Fourthly, executed.");
    ...
});

// #2 RPC decorator.
cb.rpcDecorator((delegate, ctx, rpcReq) -> {
    System.err.println("Secondly, executed.");
    ...
});

// #1 HTTP decorator.
cb.decorator((delegate, ctx, httpReq) -> {
    System.err.println("Thirdly, executed.");
    ...
});

// #1 RPC decorator.
cb.rpcDecorator((delegate, ctx, rpcReq) -> {
    System.err.println("Firstly, executed.");
    ...
});
`}</code></pre>
    <p>{`An RPC request is converted into an HTTP request before it's sent to a server.
Therefore, RPC decorators are applied before the RPC request is converted and HTTP decorators are applied
after the request is converted into the HTTP request, as described in the following diagram:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "848",
        "height": "112",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "848",
          "height": "112"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "90",
          "y": "12"
        }}>{`req`}</text>{`
  `}<text parentName="svg" {...{
          "x": "234",
          "y": "12"
        }}>{`req`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "112,20 120,24 112,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "256,20 264,24 256,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "392,20 400,24 392,28",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "90",
          "y": "60"
        }}>{`res`}</text>{`
  `}<text parentName="svg" {...{
          "x": "234",
          "y": "60"
        }}>{`res`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "88,68 80,72 88,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "232,68 224,72 232,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "376,68 368,72 376,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "376",
          "y1": "72",
          "x2": "400",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "650",
          "y": "12"
        }}>{`req`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "504",
          "y1": "24",
          "x2": "528",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "528,20 536,24 528,28",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "672,20 680,24 672,28",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "816",
          "y1": "22",
          "x2": "824",
          "y2": "22",
          "className": "solid"
        }}></line>{`
  `}<line parentName="svg" {...{
          "x1": "816",
          "y1": "26",
          "x2": "824",
          "y2": "26",
          "className": "solid"
        }}></line>{`
  `}<line parentName="svg" {...{
          "x1": "824",
          "y1": "24",
          "x2": "832",
          "y2": "24",
          "className": "solid"
        }}></line>{`
  `}<polygon parentName="svg" {...{
          "points": "832,20 840,24 832,28",
          "className": "filled"
        }}></polygon>{`
  `}<text parentName="svg" {...{
          "x": "650",
          "y": "60"
        }}>{`res`}</text>{`
  `}<polygon parentName="svg" {...{
          "points": "512,68 504,72 512,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "648,68 640,72 648,76",
          "className": "filled"
        }}></polygon>{`
  `}<polygon parentName="svg" {...{
          "points": "792,68 784,72 792,76",
          "className": "filled"
        }}></polygon>{`
  `}<line parentName="svg" {...{
          "x1": "792",
          "y1": "72",
          "x2": "800",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<line parentName="svg" {...{
          "x1": "800",
          "y1": "70",
          "x2": "808",
          "y2": "70",
          "className": "solid"
        }}></line>{`
  `}<line parentName="svg" {...{
          "x1": "800",
          "y1": "74",
          "x2": "808",
          "y2": "74",
          "className": "solid"
        }}></line>{`
  `}<line parentName="svg" {...{
          "x1": "808",
          "y1": "72",
          "x2": "840",
          "y2": "72",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "410",
          "y": "28"
        }}>{`RPC`}</text>{`
  `}<text parentName="svg" {...{
          "x": "442",
          "y": "28"
        }}>{`to`}</text>{`
  `}<text parentName="svg" {...{
          "x": "466",
          "y": "28"
        }}>{`HTTP`}</text>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "44"
        }}>{`Thrift`}</text>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "60"
        }}>{`Client`}</text>{`
  `}<text parentName="svg" {...{
          "x": "138",
          "y": "44"
        }}>{`#1`}</text>{`
  `}<text parentName="svg" {...{
          "x": "162",
          "y": "44"
        }}>{`RPC`}</text>{`
  `}<text parentName="svg" {...{
          "x": "138",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "44"
        }}>{`#2`}</text>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "44"
        }}>{`RPC`}</text>{`
  `}<text parentName="svg" {...{
          "x": "282",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "554",
          "y": "44"
        }}>{`#1`}</text>{`
  `}<text parentName="svg" {...{
          "x": "578",
          "y": "44"
        }}>{`HTTP`}</text>{`
  `}<text parentName="svg" {...{
          "x": "554",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "698",
          "y": "44"
        }}>{`#2`}</text>{`
  `}<text parentName="svg" {...{
          "x": "722",
          "y": "44"
        }}>{`HTTP`}</text>{`
  `}<text parentName="svg" {...{
          "x": "698",
          "y": "60"
        }}>{`decorator`}</text>{`
  `}<text parentName="svg" {...{
          "x": "410",
          "y": "76"
        }}>{`HTTP`}</text>{`
  `}<text parentName="svg" {...{
          "x": "450",
          "y": "76"
        }}>{`to`}</text>{`
  `}<text parentName="svg" {...{
          "x": "474",
          "y": "76"
        }}>{`RPC`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "76",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "8",
            "x2": "4",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "76",
            "y1": "8",
            "x2": "76",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "76",
            "y1": "24",
            "x2": "112",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "88",
            "x2": "76",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "124",
            "y1": "8",
            "x2": "220",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "124",
            "y1": "8",
            "x2": "124",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "220",
            "y1": "8",
            "x2": "220",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "220",
            "y1": "24",
            "x2": "256",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "88",
            "y1": "72",
            "x2": "124",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "124",
            "y1": "88",
            "x2": "220",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "8",
            "x2": "364",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "8",
            "x2": "268",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "364",
            "y1": "8",
            "x2": "364",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "364",
            "y1": "24",
            "x2": "392",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "232",
            "y1": "72",
            "x2": "268",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "268",
            "y1": "88",
            "x2": "364",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "540",
            "y1": "8",
            "x2": "636",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "540",
            "y1": "8",
            "x2": "540",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "636",
            "y1": "8",
            "x2": "636",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "636",
            "y1": "24",
            "x2": "672",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "512",
            "y1": "72",
            "x2": "540",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "540",
            "y1": "88",
            "x2": "636",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "684",
            "y1": "8",
            "x2": "780",
            "y2": "8",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "684",
            "y1": "8",
            "x2": "684",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "780",
            "y1": "8",
            "x2": "780",
            "y2": "88",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "780",
            "y1": "24",
            "x2": "816",
            "y2": "24",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "648",
            "y1": "72",
            "x2": "684",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "684",
            "y1": "88",
            "x2": "780",
            "y2": "88",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`If the decorator modifies the response (e.g. `}<a parentName="p" {...{
        "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
      }}>{`type://DecodingClient`}</a>{`) or spawns more requests
(e.g. `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`), the outcome may be different depending on the order of the decorators.
Let's look at the following example that `}<a parentName="p" {...{
        "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
      }}>{`type://DecodingClient`}</a>{` and `}<a parentName="p" {...{
        "href": "type://ContentPreviewingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/ContentPreviewingClient.html"
      }}>{`type://ContentPreviewingClient`}</a>{`
are used together:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.encoding.DecodingClient;
import com.linecorp.armeria.client.logging.ContentPreviewingClient;

ClientBuilder cb = Clients.builder(...);
cb.decorator(DecodingClient.newDecorator());

// ContentPreviewingClient should be applied after DecodingClient.
cb.decorator(ContentPreviewingClient.newDecorator(1000));
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
      }}>{`type://DecodingClient`}</a>{` decodes the content of HTTP responses. `}<a parentName="p" {...{
        "href": "type://ContentPreviewingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/ContentPreviewingClient.html"
      }}>{`type://ContentPreviewingClient`}</a>{` is
`}<a parentName="p" {...{
        "href": "/docs/advanced-structured-logging#enabling-content-previews"
      }}>{`Enabling content previews`}</a>{`
of the HTTP response by setting it to the `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{`.
Because it's evaluated after `}<a parentName="p" {...{
        "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
      }}>{`type://DecodingClient`}</a>{` from the point of view of an
HTTP response, which means that the response content is set after it's decoded, you will see the decoded
response content preview. If the two decorators are added in the opposite order, you will get the encoded
preview because `}<a parentName="p" {...{
        "href": "type://ContentPreviewingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/ContentPreviewingClient.html"
      }}>{`type://ContentPreviewingClient`}</a>{` is evaluated first for the HTTP response.
For `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`, please check out
`}<a parentName="p" {...{
        "href": "/docs/client-retry#retryingclient-with-logging"
      }}><inlineCode parentName="a">{`RetryingClient`}</inlineCode>{` with logging`}</a>{`.`}</p>
    <h2 {...{
      "id": "modifying-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#modifying-responses",
        "aria-label": "modifying responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Modifying responses`}</h2>
    <p>{`We can modify the responses in a decorator via the `}<a parentName="p" {...{
        "href": "type://HttpResponse#mapHeaders(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#mapHeaders(java.util.function.Function)"
      }}>{`type://HttpResponse#mapHeaders(Function)`}</a>{`,
`}<a parentName="p" {...{
        "href": "type://HttpResponse#mapData(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#mapData(java.util.function.Function)"
      }}>{`type://HttpResponse#mapData(Function)`}</a>{`, `}<a parentName="p" {...{
        "href": "type://HttpResponse#mapInformational(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#mapInformational(java.util.function.Function)"
      }}>{`type://HttpResponse#mapInformational(Function)`}</a>{`
or `}<a parentName="p" {...{
        "href": "type://HttpResponse.mapTrailers(Function)"
      }}>{`type://HttpResponse.mapTrailers(Function)`}</a>{` methods, or, if more advanced/stateful
processing is necessary, by wrapping the `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{` in a `}<a parentName="p" {...{
        "href": "type://FilteredHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FilteredHttpResponse.html"
      }}>{`type://FilteredHttpResponse`}</a>{`.`}</p>
    <h3 {...{
      "id": "using-transforming-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-transforming-methods",
        "aria-label": "using transforming methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using transforming methods`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.client.Clients;
import com.linecorp.armeria.common.HttpData;
import java.nio.charset.StandardCharsets;
import java.util.Arrays;
import java.util.concurrent.atomic.AtomicBoolean;

ClientBuilder cb = Clients.builder("http");
cb.decorator((delegate, ctx, req) -> {
    final AtomicBoolean initialHttpData = new AtomicBoolean(true);
    return delegate
        .execute(ctx, req)
        .mapHeaders(headers -> headers.toBuilder().add("x-foo", "foobar").build())
        .mapTrailers(trailers -> ...)
        .mapInformational(informationalHeader -> ...)
        .mapData((httpData) -> {
            HttpData result = httpData;
            if (initialHttpData.get()) {
                initialHttpData.set(false);
                byte[] ascii = "{\\"foo\\": \\"foobar\\",".getBytes(StandardCharsets.US_ASCII);
                byte[] combined = Arrays.copyOf(ascii, ascii.length + httpData.length() - 1);
                System.arraycopy(httpData.array(), 1, combined, ascii.length, httpData.length());
                result = HttpData.wrap(combined);
            }
            return result;
        });
});
`}</code></pre>
    <h3 {...{
      "id": "using-typefilteredhttpresponse",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-typefilteredhttpresponse",
        "aria-label": "using typefilteredhttpresponse permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h3" {...{
        "href": "type://FilteredHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FilteredHttpResponse.html"
      }}>{`type://FilteredHttpResponse`}</a>{`:`}</h3>
    <p>{`A couple of caveats to note here.`}</p>
    <ul>
      <li parentName="ul">{`Informational response headers may occur zero or more times.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
        }}>{`type://HttpData`}</a>{` will almost always just be a part of the whole response data
unless the response has been passed through `}<a parentName="li" {...{
          "href": "type://HttpResponse#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#aggregate()"
        }}>{`type://HttpResponse#aggregate()`}</a>{`.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.client.Clients;
import com.linecorp.armeria.common.FilteredHttpResponse;
import com.linecorp.armeria.common.HttpData;
import com.linecorp.armeria.common.HttpHeaders;
import com.linecorp.armeria.common.HttpObject;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.ResponseHeaders;
import java.nio.charset.StandardCharsets;
import java.util.Arrays;

ClientBuilder cb = Clients.builder(...);
cb.decorator((delegate, ctx, req) -> {
    HttpResponse response = delegate.execute(ctx, req);
    return new FilteredHttpResponse(response) {
        boolean initialHttpData = true;
        protected HttpObject filter(HttpObject obj) {
            if (obj instanceof ResponseHeaders) {
                // Can receive multiple ResponseHeaders whose status is informational.
                ResponseHeaders responseHeaders = (ResponseHeaders) obj;
                if (responseHeaders.status().isInformational()) {
                    return obj;
                }
                // Add a "x-foo" header into the response.
                return responseHeaders.toBuilder()
                                      .add("x-foo", "foobar")
                                      .build();
            } else if (obj instanceof HttpData) {
                HttpData httpData = (HttpData) obj;
                // Example of how to insert attribute into json payload.
                // initial '{' in response is assumed to be first byte
                if (initialHttpData) {
                    initialHttpData = false;
                    byte[] ascii = "{\\"foo\\": \\"foobar\\",".getBytes(StandardCharsets.US_ASCII);
                    byte[] combined = Arrays.copyOf(ascii, ascii.length +  httpData.length() - 1);
                    System.arraycopy(httpData.array(), 1, combined, ascii.length, httpData.length());
                    return HttpData.wrap(combined);
                }
            } else {
                // Http trailers.
                assert obj instanceof HttpHeaders;
            }
            return obj;
        }
    };
});
`}</code></pre>
    <h2 {...{
      "id": "reacting-to-or-logging-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reacting-to-or-logging-responses",
        "aria-label": "reacting to or logging responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reacting to or logging responses`}</h2>
    <p>{`If modification is not required, e.g. when handling quota responses from the service, and it doesn't need to
look at the `}<a parentName="p" {...{
        "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
      }}>{`type://HttpData`}</a>{`, it can be achieved in a more performant way via
`}<inlineCode parentName="p">{`ClientRequestContext.log().whenAvailable(RequestLogProperty).thenAccept(...)`}</inlineCode>{`. Accessing the
`}<a parentName="p" {...{
        "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
      }}>{`type://ClientRequestContext`}</a>{` can be done via either wrapping in a decorator (and using the ctx or by using
`}<a parentName="p" {...{
        "href": "type://ClientRequestContextCaptor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContextCaptor.html"
      }}>{`type://ClientRequestContextCaptor`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientRequestContextCaptor;
import com.linecorp.armeria.client.Clients;
import com.linecorp.armeria.common.logging.RequestLogProperty;

HttpResponse res;
try (ClientRequestContextCaptor captor = Clients.newContextCaptor()) {
    res = webClient.get(path);
    captor.get().log().whenAvailable(RequestLogProperty.RESPONSE_HEADERS)
          .thenAccept(log -> {
              System.err.println(log.responseHeaders());
          });
}
`}</code></pre>
    <p>{`See `}<a parentName="p" {...{
        "href": "/docs/advanced-structured-logging"
      }}>{`Structured Logging`}</a>{` to learn more about the properties
you can retrieve.`}</p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/server-decorator"
        }}>{`Decorating a service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced-custom-attributes"
        }}><inlineCode parentName="a">{`RequestContext`}</inlineCode>{` custom attributes`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      